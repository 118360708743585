<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile">
      <div class="tile is-parent is-half">
        <article class="tile is-child box detail-page-tile">
          <div class="columns is-gapless">
            <div class="column">
              <p class="title">Detail Info
                <span v-show="!innerValue.isNew"
                  class="icon has-text-info tooltip is-tooltip-info"
                  :class="[ canChangeReadOnly ? 'readonly-pointer' : '' ]"
                  :data-tooltip="readOnlyView ? 'Invoice is locked' : 'Invoice is unlocked'"
                  @click="toogleReadOnly()">
                  <i class="icon mdi"
                    :class="[ readOnlyView ? 'mdi-lock-outline' : 'mdi-lock-open-outline' ]" />
                </span>
              </p>
              <p v-if="innerValue.gstPaidDate"
                class="subtitle has-text-warning">Only Reason &amp; Remarks can be updated</p>
            </div>
            <div class="column is-narrow">
              <span v-show="innerValue.isNew"
                class="tag is-success is-pulled-right tooltip is-tooltip-success"
                data-tooltip="Previous Total Adjustments Amount">Previous Adjustments {{ innerValue.prevAdjustmentAmount | formatCurrency($userInfo.locale) }}</span>
              <span v-if="innerValue.gstPaidDate"
                class="tag is-warning is-pulled-right tooltip is-tooltip-warning"
                data-tooltip="GST has been paid. Only Reason &amp; Remarks can be updated">GST paid on {{ $filters.formatDateLocale(innerValue.gstPaidDate, $userInfo.locale) }}</span>
            </div>
          </div>
          <div class="field-body">
            <div class="field">
              <label class="label">Adjustment Note No.</label>
              <div class="control">
                <input class="input is-static"
                  :value="`${innerValue.prefix}${innerValue.invoiceNo}${innerValue.suffix}`"
                  type="text"
                  placeholder="Adjustment Note No."
                  readonly>
              </div>
            </div>
            <div v-if="gstPaid || readOnlyView"
              class="field">
              <label class="label">Invoice Date</label>
              <input class="input is-static"
                :value="$filters.formatDateLocale(invoiceDate, $userInfo.locale)"
                readonly>
            </div>
            <div v-else-if="$userInfo && !gstPaid && invoiceDate"
              class="field is-narrow">
              <label class="label">Issue Date</label>
              <v-date-picker v-model="selectedIssueDate"
                :masks="formats"
                :attributes="attrs"
                :locale="$userInfo.locale"
                :min-date="firstDayOfMonth"
                @input="issueDateInputEvent"
                :timezone="$filters.getIanaTimezone()">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="field">
                    <div class="control has-icons-left">
                      <input type="text"
                        class="input"
                        placeholder="Date To..."
                        :value="inputValue"
                        v-on="inputEvents"
                        readonly>
                      <span class="icon is-small is-left">
                        <i class="mdi mdi-calendar mdi-18px" />
                      </span>
                    </div>
                  </div>
                </template>
              </v-date-picker>
            </div>
          </div>
          <div class="field">
            <label class="label">Applies to Invoice No.</label>
            <div class="control">
              <input class="input is-static"
                :value="innerValue.applyToInvoiceNo"
                type="text"
                placeholder="Applies to Invoice No."
                readonly>
            </div>
            <label class="label">Applies to Quote No.</label>
            <div class="control">
              <input class="input is-static"
                :value="innerValue.quoteNo"
                type="text"
                placeholder="Applies to Quote No."
                readonly>
            </div>
          </div>
          <div class="field">
            <label class="label">G/L Account</label>
            <div v-if="gstPaid || readOnlyView"
              class="field">
              <input class="input is-static"
                :value="selectedGlAccountObject.displayName"
                readonly>
            </div>
            <div v-else
              class="control">
              <multiselect v-if="glAccountListCombo"
                v-model="selectedGlAccountObject"
                :options="glAccountListCombo"
                placeholder="Select account"
                label="displayName"
                track-by="accountNo"
                deselect-label="Can't remove this value"
                :allow-empty="false">
                <span class="has-text-danger"
                  slot="noResult">G/L account not found.</span>
              </multiselect>
            </div>
          </div>
          <div v-if="!innerValue.isNew"
            class="field is-narrow">
            <label class="label">Exported</label>
            <div class="pretty p-icon p-curve p-smooth p-bigger">
              <input id="chk-invoice-exported"
                name="chk-invoice-exported"
                v-model="innerValue.exported"
                type="checkbox"
                :disabled="readOnlyView || !$userInfo.isSupportUser">
              <div class="state p-primary">
                <i class="icon mdi mdi-check" />
                <label>Exported</label>
              </div>
            </div>
          </div>
          <div class="is-divider" />
          <!-- for Quick and Miscellaneous invocies -->
          <div v-if="showInvoiceItems">
            <label class="label">Selected Misc. Items</label>
            <table class="table is-bordered is-striped is-narrow is-fullwidth">
              <thead>
                <tr>
                  <th>Description</th>
                  <th class="has-text-right">
                    Total
                  </th>
                </tr>
              </thead>
              <tfoot class="has-multiple-totals">
                <tr>
                  <th class="has-text-right">Total excl. GST</th>
                  <th class="has-text-right has-text-success">{{ innerValue.totalExGst | formatCurrency($userInfo.locale) }}</th>
                </tr>
                <tr>
                  <th class="has-text-right">GST</th>
                  <th class="has-text-right has-text-success">{{ innerValue.gst | formatCurrency($userInfo.locale) }}</th>
                </tr>
                <tr>
                  <th class="has-text-right">Total incl. GST</th>
                  <th class="has-text-right has-text-success">{{ innerValue.totalIncGst | formatCurrency($userInfo.locale) }}</th>
                </tr>
              </tfoot>
              <tbody v-if="activeItems.length > 0">
                <tr v-for="(item) in activeItems"
                  :key="item.invoiceItemId">
                  <td>{{ item.invoiceItemDesc }}</td>
                  <td class="has-text-right">{{ item.totalIncGst | formatNumber($userInfo.locale) }}</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td class="has-text-centered"
                    colspan="3">
                    <span icon="icon is-medium">
                      <i class="mdi mdi-36px mdi-dots-horizontal mdi-dark mdi-inactive" />
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- for Quote, GST, Excess and Owner Contribution invoices -->
          <div v-else>
            <label class="label">Adjust Revenue Totals</label>
            <table class="table is-bordered is-striped is-narrow is-fullwidth">
              <thead>
                <tr>
                  <th>Account</th>
                  <th class="has-text-right">
                    Total
                  </th>
                </tr>
              </thead>
              <tfoot class="has-multiple-totals">
                <tr>
                  <th class="has-text-right">Amount Before GST</th>
                  <th class="has-text-right has-text-success">{{ innerValue.totalExGst | formatCurrency($userInfo.locale) }}</th>
                </tr>
                <tr>
                  <th class="has-text-right">GST</th>
                  <th class="has-text-right has-text-success">{{ innerValue.gst | formatCurrency($userInfo.locale) }}</th>
                </tr>
                <tr>
                  <th class="has-text-right">Total Amount</th>
                  <th class="has-text-right has-text-success">{{ innerValue.totalExGst + innerValue.gst | formatCurrency($userInfo.locale) }}</th>
                </tr>
              </tfoot>
              <tbody>
                <tr v-for="(item) in activeItems"
                  :key="item.invoiceItemId">
                  <td>{{ item.glAccountName }}</td>
                  <td class="has-text-right">{{ item.totalExGst | formatNumber($userInfo.locale) }}</td>
                </tr>
                <tr v-if="!innerValue.gstItem.isDeleted">
                  <td>{{ innerValue.gstItem.glAccountName }}</td>
                  <td class="has-text-right">{{ innerValue.gstItem.totalExGst | formatNumber($userInfo.locale) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </article>
      </div>
      <div class="tile is-parent is-half">
        <article class="tile is-child box detail-page-tile">
          <p class="title">Adjustment Reason</p>
          <div class="field">
            <label class="label">Reason</label>
            <div class="control">
              <textarea v-if="!readOnlyView"
                class="textarea"
                v-model="innerValue.quickInvoiceDesc"
                placeholder=""
                :disabled="readOnlyView" />
              <span v-else>{{ innerValue.quickInvoiceDesc }}</span>
            </div>
          </div>
          <p class="title">Remarks</p>
          <div v-if="!readOnlyView"
            class="field">
            <div class="control is-pulled-right">
              <div class="is-pulled-right">
                <a
                  class="button is-small is-success tooltip"
                  data-tooltip="Add Remarks"
                  @click="toggleRemarkModal()">
                  <span class="icon">
                    <i class="mdi mdi-18px mdi-plus" />
                  </span>
                  <span>Remarks</span>
                </a>
              </div>
            </div>
          </div>
          <div class="field">
            <label class="label">Remarks</label>
            <table class="table is-bordered is-striped is-narrow is-fullwidth">
              <thead />
              <tbody v-if="activeRemarks.length > 0">
                <tr
                  v-for="(remark, index) in activeRemarks"
                  :key="remark.remarkId">
                  <td class="is-content-width">
                    <span class="tag is-info">{{ $filters.formatDateTimeLocale(remark.remarkDateTime, $userInfo.locale) }}</span>
                  </td>
                  <td v-if="readOnlyView">
                    <div class="control">
                      <span>{{ remark.remarks }}</span>
                    </div>
                  </td>
                  <td v-else>
                    <div class="control">
                      <textarea
                        class="textarea"
                        v-model="remark.remarks"
                        placeholder="" />
                    </div>
                  </td>
                  <td v-if="!readOnlyView"
                    class="has-text-centered is-content-width has-vertical-middle">
                    <a
                      class="button is-danger is-small is-inverted"
                      @click="deleteRemark(remark, index, remarkTypes.Remark)">
                      <span class="icon is-medium">
                        <i class="mdi mdi-delete mdi-24px" />
                      </span>
                    </a>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td
                    class="has-text-centered"
                    colspan="2">
                    <span icon="icon is-medium">
                      <i class="mdi mdi-36px mdi-comment-text-outline mdi-dark mdi-inactive" />
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="!readOnlyView"
            class="field">
            <div class="control is-pulled-right">
              <div class="is-pulled-right">
                <a
                  class="button is-small is-success tooltip"
                  data-tooltip="Add Remarks"
                  @click="toggleRemarkModal()">
                  <span class="icon">
                    <i class="mdi mdi-18px mdi-plus" />
                  </span>
                  <span>Remarks</span>
                </a>
              </div>
            </div>
          </div>
          <div class="field">
            <label class="label">Printed Remarks</label>
            <table class="table is-bordered is-striped is-narrow is-fullwidth">
              <thead />
              <tbody v-if="activePrintedRemarks.length > 0">
                <tr
                  v-for="(remark, index) in activePrintedRemarks"
                  :key="remark.remarkId">
                  <td class="is-content-width">
                    <span class="tag is-info">{{ $filters.formatDateTimeLocale(remark.remarkDateTime, $userInfo.locale) }}</span>
                  </td>
                  <td v-if="readOnlyView">
                    <div class="control">
                      <span>{{ remark.remarks }}</span>
                    </div>
                  </td>
                  <td v-else>
                    <div class="control">
                      <textarea
                        class="textarea"
                        v-model="remark.remarks"
                        placeholder="" />
                    </div>
                  </td>
                  <td v-if="!readOnlyView"
                    class="has-text-centered is-content-width has-vertical-middle">
                    <a
                      class="button is-danger is-small is-inverted"
                      @click="deleteRemark(remark, index, remarkTypes.PrintedRemark)">
                      <span class="icon is-medium">
                        <i class="mdi mdi-delete mdi-24px" />
                      </span>
                    </a>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td
                    class="has-text-centered"
                    colspan="2">
                    <span icon="icon is-medium">
                      <i class="mdi mdi-36px mdi-comment-text-outline mdi-dark mdi-inactive" />
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </article>
      </div>
    </div>
    <remark-modal
      v-if="value && isRemarkModalActive"
      :show-remark="true"
      :show-printed-remark="true"
      :show-recurring-remark="true"
      :active.sync="isRemarkModalActive"
      @add="addRemarks"
      @cancel="toggleRemarkModal()">
      <p slot="text-title">Add Debtor Adjustment Remarks</p>
    </remark-modal>
  </div>
</template>

<script>
import InsurerService from '@/views/insurer/InsurerService'
import Multiselect from 'vue-multiselect'
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import { InvoiceTypes, RemarkTypes, EventHubTypes } from '@/enums'
import { RemarkModal } from '@/components/BulmaModal'
import StoreMixin from './storeMixin'
import { RemarkModel } from '@/classes/viewmodels'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'DebtorAdjustmentDetail',
  inject: ['$vv'],
  components: {
    Multiselect,
    RemarkModal
  },
  mixins: [DateTimeFiltersMixin, NumberFiltersMixin, StoreMixin],
  props: {
    value: null,
    isNew: Boolean,
    gstPaid: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      invoiceDate: null,
      glAccountListCombo: null,
      selectedGlAccountObject: {},
      isRemarkModalActive: false,
      moneyMask: {
        precision: 2,
        prefix: '$ '
      },
      selectedIssueDate: null,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ]
    }
  },
  computed: {
    assetButtonIconClass() {
      return {
        'mdi-pencil': this.hasAssetSelected,
        'mdi-plus': !this.hasAssetSelected
      }
    },
    activeRemarks() {
      return this.innerValue.remarks.filter(r => !r.isDeleted)
    },
    activePrintedRemarks() {
      return this.innerValue.printedRemarks.filter(r => !r.isDeleted)
    },
    remarkTypes() {
      return RemarkTypes
    },
    activeItems() {
      return this.innerValue.items.filter(i => !i.isDeleted)
    },
    showInvoiceItems() {
      if (this.innerValue.applyToInvoiceType === InvoiceTypes.Quick || this.innerValue.applyToInvoiceType === InvoiceTypes.Miscellaneous) {
        return true
      } else {
        return false
      }
    },
    invoiceTypes() {
      return InvoiceTypes
    },
    $v() {
      return this.$vv || this.$v
    },
    firstDayOfMonth() {
      if (this.$company.setting.allowInvoicePreviousMonth) {
        return null
      }
      let invoiceDate = new Date()
      if (!this.innerValue.isNew) {
        invoiceDate = new Date(`${this.innerValue.invoiceDate}`)
        return this.$filters.firstDayOfMonth(invoiceDate.getFullYear(), invoiceDate.getMonth())
      } else {
        return this.$filters.firstDayOfMonth()
      }
    }
  },
  watch: {
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    selectedIssueDate: function(newVal, oldVal) {
      if (newVal) {
        this.innerValue.invoiceDate = newVal.toISOString().split('.')[0] + 'Z'
      } else {
        this.innerValue.invoiceDate = null
      }
    },
    selectedGlAccountObject: function(newVal, oldVal) {
      if (newVal != null) {
        this.innerValue.invoiceGlCode = newVal.accountNo
      } else {
        this.innerValue.invoiceGlCode = ''
      }
    },
    'innerValue.invoiceGlCode': function(newVal) {
      this.selectedGlAccountObject = this.glAccountListCombo.find(g => g.accountNo === newVal)
    }
  },
  beforeDestroy() {
    this.$eventHub.$off(EventHubTypes.EntitySaved)
    this.$eventHub.$off(EventHubTypes.EntityReload)
  },
  async created() {
    this.invoiceDate = new Date(`${this.innerValue.invoiceDate}`)
    this.selectedIssueDate = new Date(`${this.innerValue.invoiceDate}`)
    this.glAccountListCombo = await InsurerService.getGlAccountListCombo()
    this.selectedGlAccountObject = this.glAccountListCombo.find(g => g.accountNo === this.innerValue.invoiceGlCode)
  },
  mounted() {
    this.$eventHub.$on(EventHubTypes.EntitySaved, () => {
      this.innerValue = _cloneDeep(this.value)
    })

    this.$eventHub.$on(EventHubTypes.EntityReload, () => {
      this.innerValue = _cloneDeep(this.value)
    })
  },
  methods: {
    deleteRemark(remark, index, remarkType) {
      if (remark.isNew) {
        if (remarkType === this.remarkTypes.Remark) {
          this.innerValue.remarks.splice(index, 1)
        } else if (remarkType === this.remarkTypes.PrintedRemark) {
          this.innerValue.printedRemarks.splice(index, 1)
        }
      } else {
        remark.isDeleted = true
      }
    },
    addRemarks(remark, printedRemark) {
      if (remark) {
        const newRemark = new RemarkModel(this.innerValue.invoiceId, RemarkTypes.Remark, remark)
        this.innerValue.remarks.push(newRemark)
      }
      if (printedRemark) {
        const newPrintedRemark = new RemarkModel(this.innerValue.invoiceId, RemarkTypes.PrintedRemark, printedRemark)
        this.innerValue.printedRemarks.push(newPrintedRemark)
      }
      this.toggleRemarkModal()
    },
    toggleRemarkModal() {
      this.isRemarkModalActive = !this.isRemarkModalActive
    },
    issueDateInputEvent(event) {
      this.selectedIssueDate = this.$filters.fromISOWithCurrentTime(event.toISOString())
    },
    toogleReadOnly() {
      if (this.canChangeReadOnly) {
           this.setReadOnlyView(!this.readOnlyView)
      }
    },
   canChangeReadOnly() {
      return this.$userInfo.isSupportUser || this.$userInfo.isCustomerAdministrator
    }
  }
}
</script>
<style lang="scss" scoped>
.is-divider {
  margin: 1.2em 0;
}
</style>
