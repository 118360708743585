<template>
  <app-side-menu :min-width="18"
    :fixed-menu="false">
    <aside class="menu"
      slot="side-menu">
      <p class="menu-label">
        <span class="title is-6">
          Adj Inv No: {{ entityName }}
        </span>
      </p>
      <ul class="menu-list">
        <li>
          <router-link :to="{ name: routeTypes.DebtorAdjustmentDetail.name, params: { invoiceId : $route.params.invoiceId }, query: $route.query }"
            active-class="is-active"
            exact>
            <span>
              Detail Info
            </span>
          </router-link>
        </li>
        <li v-if="goToChild === 'quickmisc'">
          <router-link
            :to="{ name: routeTypes.DebtorAdjustmentQuickMiscInvoice.name, params: { invoiceId : $route.params.invoiceId }, query: $route.query }"
            active-class="is-active"
            exact>
            <span
              :class="{ 'has-badge-danger has-badge-small has-badge-rounded has-badge-offset2': $v.entity.$error }"
              :data-badge="$v.entity.$error ? '' : null">
              Items and G/L
            </span>
          </router-link>
        </li>
        <li v-if="goToChild === 'revenuetotals'">
          <router-link
            :to="{ name: routeTypes.DebtorAdjustmentRevenueTotals.name, params: { invoiceId : $route.params.invoiceId }, query: $route.query }"
            active-class="is-active"
            exact>
            <span>
              Adjust Revenue Totals
            </span>
          </router-link>
        </li>
      </ul>
    </aside>
  </app-side-menu>
</template>

<script>
import AppSideMenu from '@/components/AppSideMenu'
import DebtorAdjustmentRoutes from './route-types'

export default {
  name: 'DebtorAdjustmentSideMenu',
  inject: ['$vv'],
  components: {
    AppSideMenu
  },
  props: {
    entityName: {
      type: String,
      default: ''
    },
    goToChild: {
      type: String,
      default: null
    }
  },
  data() {
    return {}
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    routeTypes() {
      return DebtorAdjustmentRoutes
    }
  },
  mounted() {},
  methods: {}
}
</script>
