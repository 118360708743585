import { required } from 'vuelidate/lib/validators'

export default {
  provide: function() {
    return {
      $vv: this.$v
    }
  },
  validations: {
    entity: {
      items: {
        $each: {
          invoiceItemDesc: {
            required
          }
        }
      }
    },
    filter: {
      dateFrom: {
        validDate: true // aussieDateValidator
      },
      dateTo: {
        validDate: true // aussieDateValidator
      }
    }
  }
}
