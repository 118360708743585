<template>
  <div>
    <app-detail-header :show-toggle="true"
      :show-new="false"
      :show-print="entity && !entity.isNew"
      :show-delete="entity && !entity.deleted && !entity.isNew && !gstPaid && $userInfo.isSupportUser && !readOnlyView"
      :show-save="entity && !entity.deleted && !readOnlyView"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <debtor-adjustment-side-menu v-if="entity"
          ref="sideMenu"
          :entity-name="`${entity.prefix}${entity.invoiceNo}${entity.suffix}`"
          :go-to-child="goToChild" />
      </div>
      <div class="column">
        <router-view v-if="entity"
          v-model="entity"
          ref="currentChild"
          :is-tiled="false"
          :key="$route.fullPath"
          :route-name="$route.name"
          :gst-paid="gstPaid"
          :read-only-view="readOnlyView" />
      </div>
    </div>
    <unsaved-modal :active.sync="isUnsavedModalActive"
      @close="closeModal()"
      @skipSave="skipSave()"
      @saveContinue="saveContinue()">
      <p slot="text-title">Unsaved Changes</p>
      <p slot="text-content">There are unsaved changes. Please select action below</p>
    </unsaved-modal>
    <save-conflict-modal :active.sync="isSaveConflictModalActive"
      @close="closeModal()"
      @reload="reloadData()">
      <p slot="text-title">Change conflict</p>
      <p slot="text-content">The data on the server is newer than the local copy. Please reload local data.</p>
    </save-conflict-modal>
    <confirm-modal v-if="entity"
      :active.sync="isConfirmModalActive"
      @ok="deleteEntity(true)"
      @cancel="deleteEntity(false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Delete Debtor Adjustment</p>
      <p slot="text-content">
        Adjustment Note No.
        <span class="has-text-primary has-text-weight-bold">{{ entity.prefix }}{{ entity.invoiceNo }}{{ entity.suffix }} </span> will be deleted. Continue?
      </p>
    </confirm-modal>
  </div>
</template>

<script>
import DebtorAdjustmentSideMenu from './DebtorAdjustmentSideMenu'
import DebtorAdjustmentService from './DebtorAdjustmentService'
import DebtorAdjustmentValidation from './DebtorAdjustmentValidation'
import DebtorAdjustmentRoutes from './route-types'
import AppDetailHeader from '@/components/AppDetailHeader'
import { UnsavedModal, SaveConflictModal, ConfirmModal } from '@/components/BulmaModal'
import HttpStatus from '@/components/http-status'
import { AppHeaderButtonTypes, EventHubTypes, RemarkTypes, InvoiceTypes } from '@/enums'
import StoreMixin from './storeMixin'
import _cloneDeep from 'lodash/cloneDeep'
import _debounce from 'lodash.debounce'
import _isEmpty from 'lodash/isEmpty'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { Emailer } from '@/classes'
import { KeyValuePairModel } from '@/classes/viewmodels'

export default {
  name: 'DebtorAdjustmentView',
  components: {
    AppDetailHeader,
    [DebtorAdjustmentSideMenu.name]: DebtorAdjustmentSideMenu,
    UnsavedModal,
    SaveConflictModal,
    ConfirmModal
  },
  mixins: [DebtorAdjustmentValidation, StoreMixin],
  props: {
    isNew: Boolean,
    returnUrl: String,
    type: String
  },
  data() {
    return {
      entity: null,
      isUnsavedModalActive: false,
      isSaveConflictModalActive: false,
      isConfirmModalActive: false,
      isSkipSave: false,
      isSaveContinue: false,
      toRoute: null // stores "to" route of beforeRouteLeave
    }
  },
  computed: {
    validateError() {
      return this.$v.$error || this.$v.entity.items.$error
    },
    activeItems() {
      return this.entity.items.filter(i => !i.isDeleted)
    },
    gstPaid() {
      return this.entity.gstPaidDate != null
    },
    goToChild() {
      let invoiceType = this.entity.invoiceType
      let applyToInvoiceType = this.entity.applyToInvoiceType
      if (invoiceType == InvoiceTypes.Adjustment && (applyToInvoiceType == InvoiceTypes.Quick || applyToInvoiceType == InvoiceTypes.Miscellaneous))
      {
        return 'quickmisc'
      } else {
        return 'revenuetotals'
      }
    }
  },
  watch: {
    entity: {
      handler: _debounce(function(newVal) {
        if (newVal) {
          this.$forceUpdate()
          this.saveSnapshot(_cloneDeep(this.entity))
        }
      }, 500),
      deep: true
    }
  },
  beforeDestroy() {
    if (_isEmpty(this.returnRoute)) {
      this.setReadOnlyView(false)
    }
  },
  async created() {
    if (this.returnUrl) {
      this.persistQueries()
      this.replaceRoute(this.$route.params.invoiceId)
    }
    this.getEntity()
  },
  mounted() {},
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          break
        case AppHeaderButtonTypes.Print:
          this.print1()
          break
        case AppHeaderButtonTypes.Delete:
          this.isConfirmModalActive = true
          break
        case AppHeaderButtonTypes.Save:
          this.save()
          break
        case AppHeaderButtonTypes.Cancel:
          this.cancel()
          break
      }
    },
    async getEntity(reset = false) {
      try {
        this.$showSpinner()
        if (reset) {
          this.clearSessionStorage()
          this.clearSnapshots(this.$route.params.invoiceId)
        }
        if (!this.currentSnapshot) {
          await this.getStoreItem(this.$route.params.invoiceId)
        }
        this.entity = _cloneDeep(this.currentSnapshot)
      } catch (e) {
        this.$notification.openMessageXhrError('', e)
      }
      this.$hideSpinner()
    },
    async save(isDelete) {
      const title = 'Debtor Adjustment'
      if (this.validateError) {
        this.$notification.openNotificationWithType('danger', title, 'Validation errors. Please fix before saving')
        return false
      }
      try {
        this.$showSpinner('Saving...')
        let response
        this.saveSnapshot(_cloneDeep(this.entity))
        if (this.entity.isNew) {
          response = await DebtorAdjustmentService.postEntity(this.entity)
        } else if (this.snapshotDiff) {
          response = await DebtorAdjustmentService.putEntity(_cloneDeep(this.entity), this.snapshotDiff)
        } else {
          this.$notification.openNotificationWithType('warning', title, 'No changes. Not saved')
        }
        this.$hideSpinner()
        if (this.isSaveContinue) {
          this.$notification.openNotificationWithType('success', title, isDelete ? 'Debtor Adjustment deleted' : 'Save successful')
        } else if (response && response.status === HttpStatus.NO_CONTENT) {

          await this.getEntity(true)
          this.$notification.openNotificationWithType('success', title, isDelete ? 'Debtor Adjustment deleted' : 'Save successful')
        } else if (response && response.status === HttpStatus.CREATED) {
          await this.getEntity(true)
          this.$notification.openNotificationWithType('success', title, 'New Debtor Adjustment added')
        }
        this.$eventHub.$emit(EventHubTypes.EntitySaved)
        return true
      } catch (e) {
        this.$hideSpinner()
        if (e.response.request.status === HttpStatus.CONFLICT) {
          this.isSaveConflictModalActive = true
        } else {
          this.$notification.openMessageXhrError('Debtor Adjustment', e)
        }
        return false
      }
    },
    deleteEntity(confirmDelete) {
      this.isConfirmModalActive = false
      if (confirmDelete) {
        this.entity.deleted = true
        // save snapshots immediately not waiting for debounce
        this.save(true)
      }
    },
    cancel() {
      this.saveSnapshot(_cloneDeep(this.entity))
      if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue) {
        this.isUnsavedModalActive = true
      } else {
        if (this.returnUrl) {
          this.clearSessionStorage()
          this.clearSnapshots(this.entity.invoiceId)
          window.location.href = `${process.env.VUE_APP_ROOT_URI}${this.returnUrl}`
        } else if (this.toRoute) {
          this.$router.push(this.toRoute.fullPath)
        } else if (this.returnRoute && !_isEmpty(this.returnRoute)) {
          this.$router.push(this.returnRoute.fullPath)
        } else {
          this.$router.push({
            name: DebtorAdjustmentRoutes.DebtorAdjustmentListView.name
          })
        }
      }
    },
    print() {
      const params = {
        reportname: 'AT_DebtorAdjustment',
        INVOICEID: this.entity.invoiceId,
        REPORTTITLE: 'TAX INVOICE',
        COMPANYNAME: encodeURIComponent(this.$userInfo.companyName),
        COMPANYID: this.$userInfo.companyId,
        timezoneoffset: this.$filters.getSiteTimezoneOffset()
      }
      const url =
        `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx?` +
        Object.keys(params)
          .map(e => `${e}=${params[e]}`)
          .join('&')
      window.open(url, '_blank')
    },
    async saveContinue() {
      this.isUnsavedModalActive = false
      this.isSaveContinue = true
      this.isSaveContinue = await this.save()
      if (this.isSaveContinue) {
        this.cancel()
      }
    },
    async print1() {
      const params = {
        INVOICENO: this.entity.invoiceNo,
        COMPANYNAME: encodeURIComponent(this.$userInfo.companyName),
        REPORTTITLE: 'ADJUSTMENT NOTE',
        COMPANYID: this.$userInfo.companyId,
        INVOICEPREFIX: this.entity.prefix,
        INVOICESUFFEX: this.entity.suffix,
        CustomerID: this.entity.assetId,
        INVOICEID: this.entity.invoiceId
      }
      // Create KeyValuePairs and add to vuex for later retrieval by PrintPreviewEx component
      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)

      const reportName = `${this.entity.prefix}${this.entity.invoiceNo}${this.entity.suffix}`

      const emailer = new Emailer()
      emailer.assetId = this.entity.invoiceId
      emailer.remark = `Invoice ${this.entity.prefix}${this.entity.invoiceNo}${this.entity.suffix}`
      emailer.remarkTypeId = RemarkTypes.Remark
      emailer.subject = `Invoice ${this.entity.prefix}${this.entity.invoiceNo}${this.entity.suffix}`
      emailer.reportName = reportName.replace(/[/]/g, '_')
      this.addEmailer(emailer)
      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: 'AT_AdjustmentNote' },
        query: { parameterId: this.$guid.newGuid(), emailerId: emailer.id }
      })
    },
    skipSave() {
      this.isUnsavedModalActive = false
      this.isSkipSave = true
      this.cancel()
    },
    redirectToRoute() {
      if (this.toRoute) {
        this.$router.push(this.toRoute.fullPath)
      } else if (!this.entity.isNew) {
        this.cancel()
      }
    },
    closeModal() {
      this.isUnsavedModalActive = false
      this.isSaveConflictModalActive = false
      this.isSaveContinue = false
    },
    async reloadData() {
      this.isSaveConflictModalActive = false
      this.isSaveContinue = false
      await this.getEntity(true)
      this.$eventHub.$emit(EventHubTypes.EntityReload)
    },
    persistQueries() {
      if (this.returnUrl) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|debtoradjustment|returnUrl`, this.returnUrl)
      }
      if (this.type) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|debtoradjustment|type`, this.type)
      }
    },
    removeQueries() {
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|debtoradjustment|returnUrl`)
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|debtoradjustment|type`)
    },
    clearSessionStorage() {
      this.removeQueries()
    },
    replaceRoute(id) {
      const newMeta = Object.assign(this.$route.meta, {
        returnUrl: this.returnUrl,
        type: this.type
      })
      this.$router.replace({
        name: DebtorAdjustmentRoutes.DebtorAdjustmentDetail.name,
        params: { invoiceId: id },
        meta: newMeta
      })
    },
    clearRouteMeta() {
      // Reset returnUrl meta in case user clicked on other V2
      if (this.$route.meta.returnUrl) {
        this.$route.meta.returnUrl = null
      }
    }
  },
  beforeRouteLeave: async function(to, from, next) {
    this.saveSnapshot(_cloneDeep(this.entity))
    if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue) {
      console.log(this.snapshotDiff)
      this.$router.replace(from.path)
      this.toRoute = to
      this.isUnsavedModalActive = true
    } else {
      // Clean vuex store for now
      this.clearSnapshots(this.entity.invoiceId)
      this.clearSessionStorage()
      this.clearRouteMeta()
      if (to.name !== PrintPreviewRoutes.PrintPreview.name) {
        this.setReturnRoute({})
      }
      next()
    }
  }
}
</script>
